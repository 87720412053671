import { t } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { showTestnetsAtom } from 'components/AccountDrawer/TestnetsToggle'
import { MouseoverTooltip } from 'components/Tooltip'
import { getConnection } from 'connection'
import { ConnectionType } from 'connection/types'
import { WalletConnectV2 } from 'connection/WalletConnectV2'
import { getChainInfo } from 'constants/chainInfo'
import { getChainPriority, L1_CHAIN_IDS, TESTNET_CHAIN_IDS } from 'constants/chains'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { useAtomValue } from 'jotai/utils'
import { Box } from 'nft/components/Box'
import { Portal } from 'nft/components/common/Portal'
import { Column, Row } from 'nft/components/Flex'
import { useIsMobile } from 'nft/hooks'
import { useCallback, useMemo, useRef, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme/components'
import { flexColumnNoWrap } from 'theme/styles'
import { getSupportedChainIdsFromWalletConnectSession } from 'utils/getSupportedChainIdsFromWalletConnectSession'

import * as styles from './ChainSelector.css'
import ChainSelectorRow from './ChainSelectorRow'
import { NavDropdown } from './NavDropdown'

const NETWORK_SELECTOR_CHAINS = [...L1_CHAIN_IDS]

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  border-radius: 12px;
  background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.surface2)};
  width: '100%';

  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

interface ChainSelectorProps {
  leftAlign?: boolean
  inputMode?: boolean
}

function useWalletSupportedChains(): ChainId[] {
  const { connector } = useWeb3React()
  const connectionType = getConnection(connector).type

  switch (connectionType) {
    case ConnectionType.WALLET_CONNECT_V2:
      return getSupportedChainIdsFromWalletConnectSession((connector as WalletConnectV2).provider?.session)
    default:
      return NETWORK_SELECTOR_CHAINS
  }
}

export const ChainSelector = ({ leftAlign, inputMode }: ChainSelectorProps) => {
  const { chainId } = useWeb3React()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const theme = useTheme()

  const showTestnets = useAtomValue(showTestnetsAtom)
  const walletSupportsChain = useWalletSupportedChains()

  const [supportedChains, unsupportedChains] = useMemo(() => {
    const { supported, unsupported } = NETWORK_SELECTOR_CHAINS.filter((chain: number) => {
      return showTestnets || !TESTNET_CHAIN_IDS.includes(chain)
    })
      .sort((a, b) => getChainPriority(a) - getChainPriority(b))
      .reduce(
        (acc, chain) => {
          if (walletSupportsChain.includes(chain) && ChainId.BNB !== chain && ChainId.MAINNET !== chain) {
            acc.supported.push(chain)
          } else {
            acc.unsupported.push(chain)
          }
          return acc
        },
        { supported: [], unsupported: [] } as Record<string, ChainId[]>
      )
    return [supported, unsupported]
  }, [showTestnets, walletSupportsChain])

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const info = getChainInfo(chainId)

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<ChainId | undefined>(undefined)

  const onSelectChain = useCallback(
    async (targetChainId: ChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen]
  )

  if (!chainId) {
    return null
  }
  // const isSupported = !!info
  const isSupported = !!info && chainId === ChainId.POLYGON

  const dropdown = (
    <NavDropdown
      style={{ backgroundColor: theme.surface1 }}
      top="64"
      left={leftAlign ? '0' : 'auto'}
      right={leftAlign ? 'auto' : '0'}
      ref={modalRef}
      width="full"
      zIndex="3"
    >
      <Column paddingX="8" data-testid="chain-selector-options">
        {supportedChains.map((selectorChain) => (
          <ChainSelectorRow
            disabled={!walletSupportsChain.includes(selectorChain)}
            onSelectChain={onSelectChain}
            targetChain={selectorChain}
            key={selectorChain}
            isPending={selectorChain === pendingChainId}
            fullWidth
          />
        ))}
        {unsupportedChains.map((selectorChain) => (
          <ChainSelectorRow
            disabled
            onSelectChain={() => undefined}
            targetChain={selectorChain}
            key={selectorChain}
            isPending={false}
            fullWidth
          />
        ))}
      </Column>
    </NavDropdown>
  )

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.neutral2,
  }

  return (
    <Box style={inputMode ? { width: '100%' } : {}} position="relative" ref={ref}>
      {inputMode ? (
        <InputPanel>
          <MouseoverTooltip text={t`Your wallet's current network is unsupported.`} disabled={isSupported}>
            <Row
              data-testid="chain-selector"
              as="button"
              gap="8"
              className={styles.ChainSelector}
              background={isOpen ? 'accent2' : 'none'}
              onClick={() => setIsOpen(!isOpen)}
              padding="32"
              width="full"
            >
              {!isSupported ? (
                <>
                  <AlertTriangle size={20} color={theme.neutral2} />
                  <ThemedText.BodySmall>Your wallet's current network is unsupported.</ThemedText.BodySmall>
                </>
              ) : (
                <>
                  <img src={info.logoUrl} alt={info.label} className={styles.Image} data-testid="chain-selector-logo" />
                  <ThemedText.BodySmall data-testid="chain-selector-label">{info.label}</ThemedText.BodySmall>
                </>
              )}
              {/*{isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}*/}
            </Row>
          </MouseoverTooltip>
        </InputPanel>
      ) : (
        <MouseoverTooltip text={t`Your wallet's current network is unsupported.`} disabled={isSupported}>
          <Row
            data-testid="chain-selector"
            as="button"
            gap="8"
            className={styles.ChainSelector}
            background={isOpen ? 'accent2' : 'none'}
            onClick={() => setIsOpen(!isOpen)}
          >
            {!isSupported ? (
              <div>
                <AlertTriangle size={20} color={theme.neutral2} />
              </div>
            ) : (
              <img src={info.logoUrl} alt={info.label} className={styles.Image} data-testid="chain-selector-logo" />
            )}
            {/*{isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}*/}
          </Row>
        </MouseoverTooltip>
      )}
      {isOpen && (isMobile && !inputMode ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </Box>
  )
}
